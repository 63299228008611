
<template>
    <div>
      <div class="" v-if="loading">
        <app-spinner></app-spinner>
      </div>
      <v-row v-else>
        
       
        <template >
        <v-col md="4" >
          <div style="cursor: pointer; "  @click="
                  $router.push(`lessonReport`)
                ">
            <v-card elevation="3" class="mt-5 mx-5" width="320" style="background-color: var(--card-report-background); color: white;" >
            <v-card-title class="justify-center">
              
              <h2>{{ $t('Lessons Report') }}</h2>
             
            </v-card-title>
            <!-- <v-icon class="px-1 py-0">mdi-scale-unbalanced</v-icon> -->
            <v-card-actions class="justify-space-between"  >
              
            </v-card-actions>
          </v-card>
          </div>
        </v-col>
      </template>
       
      </v-row>
    </div>
  </template>

 <script>
 import axios from "axios";

 export default {
   components: {
    
   },
   data() {
     return {
       items: [],
       loading: false,
       templates: [],
     };
   },
   methods: {
    
    
   },
   created() {
    

   },
 };
 </script>
 
  <style>
  .accounting-templates__spinner-wrapper {
    width: 100%;
    height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>